<template>
    <div class="package-contents">

      <div class="back-desc">
        <div ref="package-desc" class="package-contents-desc"
             v-html="`We provide<b>Full-Package</b>
          for business in need
          of server and operation
          tools.`"></div>
      </div>

      <div class="front-desc"
           :style="{width: `${packageRightBg}%`,}">
        <div class="wrap">
          <div ref="package-desc" class="package-contents-desc"
               v-html="`We provide<b>Full-Package</b>
          for business in need
          of server and operation
          tools.`"></div>
        </div>
      </div>

      <div :class="`package-desc-back${packageRightBg > 50 ? ' on' : ''}`"
           :style="{
                   left: `${pos.left}px`,
                   top: `${pos.top}px`,
                   width: `${pos.width}px`,
                   height: `${pos.height}px`,
                 }"></div>

    </div>
</template>

<script>
import {debounce} from "lodash";

export default {
  name: 'Package',
  props: ['packageRightBg', 'packageHeight'],
  data: function () {
    return {
      pos: {left:0,top:0,width:0, height:0},
    }
  },
  created() {
    window.addEventListener('resize',  debounce(this.setWH, 100), { passive: true });
  },
  mounted() {
    this.setWH();
  },
  unmounted() {
    window.removeEventListener('resize', this.setWH);
  },
  methods: {
    setWH() {
      if (this.packageHeight && this.$refs["package-desc"]) {
        const Rect = this.$refs["package-desc"].getBoundingClientRect();
        this.pos.left = Rect.x;
        this.pos.top = Math.round((this.packageHeight - Rect.height) / 2);
        this.pos.width = Rect.width;
        this.pos.height = Rect.height;
      }
    },
  },
  watch: {
    packageHeight(Num) {
      if (Num !== 0) this.setWH();
    }
  }
}
</script>