import { createApp } from 'vue'
import App from './App.vue'
import AOS from 'aos'
import store from './store'

import "animate.css";
import 'aos/dist/aos.css'
import '/src/assets/scss/style.scss'

export const app = createApp(App);

app.AOS = new AOS.init();

app.use(store)
    .use(AOS)
    .mount('#app')
