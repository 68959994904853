<template>
  <div class="body-contents">
    <div class="background-views">
      <div class="bg-intro"></div>
    </div>

    <div class="contents-views">
      <section
        ref="intro"
        :class="`section intro ${bgRight === 0 ? 'on' : ''}`"
      >
        <div class="contents">
          <div class="thumb">
            <img alt="" src="@/assets/images/intro-dash.png" />
          </div>
          <h1 data-aos="fade-up">
            <img alt="METAVERSE WORLD" src="@/assets/images/intro-title.svg" />
          </h1>
          <p data-aos="fade-up" data-aos-duration="1000">
            {{
              `B2B Solutions for the
        Next Generation of Entertainment`
            }}
          </p>
          <a href="mailto: contact@nm-metaworld.com" class="btn js-mail">
            <span class="material-icons-outlined">email</span>
            Business Proposal
          </a>
        </div>
        <div class="dash1">
          <img alt="METAVERSE WORLD" src="@/assets/images/intro-dash2.png" />
        </div>
        <div class="dash2">
          <img alt="METAVERSE WORLD" src="@/assets/images/intro-dash3.png" />
        </div>
      </section>

      <section ref="package" class="section package">
        <Package
          :package-right-bg="packageRightBg"
          :package-height="packageHeight"
        />
      </section>

      <section ref="eaas" :class="`section eaas ${bgRight > 1 ? 'on' : ''}`">
        <div class="contents">
          <div class="info">
            <h4>( Eaas )</h4>
            <h3 data-aos="fade-up">
              {{
                `Entertainment -
             as-a-Service`
              }}
            </h3>

            <ul data-aos="fade-up" data-aos-duration="1000">
              <li>
                <img alt="" src="@/assets/images/ico-solution.svg" /> Server
                Solutions
              </li>
              <li>
                <img alt="" src="@/assets/images/ico-manage.svg" /> Asset
                Management
              </li>
              <li>
                <img alt="" src="@/assets/images/ico-chat.svg" /> Text / voice
                Chat
              </li>
              <li>
                <img alt="" src="@/assets/images/ico-data.svg" /> Data
                Collection
              </li>
            </ul>
          </div>

          <div class="connect-wrap">
            <div class="block">
              <div class="center">
                <h4>
                  <img alt="MADE EASY" src="@/assets/images/tit-madeeasy.svg" />
                </h4>
              </div>

              <ul>
                <li>
                  <div class="cell">
                    <div class="ico">
                      <img alt="" src="@/assets/images/ico-chat.svg" />
                    </div>
                    <div>WEB</div>
                  </div>
                </li>
                <li>
                  <div class="cell">
                    <div class="ico">
                      <img alt="" src="@/assets/images/ico-data.svg" />
                    </div>
                    <div>SOCIAL</div>
                  </div>
                </li>
                <li>
                  <div class="cell">
                    <div class="ico">
                      <img alt="" src="@/assets/images/ico-manage.svg" />
                    </div>
                    <div>CONTENT</div>
                  </div>
                </li>
                <li>
                  <div class="cell">
                    <div class="ico">
                      <img alt="" src="@/assets/images/ico-solution.svg" />
                    </div>
                    <div>MEDIA</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="dash">
          <img alt="METAVERSE WORLD" src="@/assets/images/intro-dash3.png" />
        </div>
      </section>

      <section ref="foot" :class="`section foot ${bgRight > 2 ? 'on' : ''}`">
        <div data-aos="fade-up" data-aos-duration="1000" class="contents">
          <div
            class="desc"
            v-html="
              `<b>Take your next</b> leap
           forward as a Metaverse
           with us.`
            "
          ></div>
          <div class="copyright">
            <a
              href="https://policy.metaverseworld.zone/privacy?clientId=cube&lang=en"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <div class="copyright-wrap">
              <img alt="" src="@/assets/images/ico-dash.svg" />
              <span>{{
                `©2022 Metaverse World
            All rights reserved`
              }}</span>
            </div>
          </div>
        </div>
      </section>

      <footer class="footer"></footer>

      <button
        type="button"
        @click="next"
        :class="`flating${bgRight > 2 ? ' hide' : ''}`"
      >
        <span class="material-icons">arrow_downward</span>
        <span>Scroll</span>
      </button>
    </div>
  </div>
</template>

<script>
import Package from "./component/package.component";
import { debounce } from "lodash";

export default {
  name: "App",
  components: {
    Package,
  },
  data: function () {
    return {
      pos: [],
      packageTop: 0,
      packageHeight: 0,
      packageRightBg: 100,
      bgRight: 0,
      gap: 200,
    };
  },
  created() {
    window.addEventListener("resize", debounce(this.setPos, 100));
    window.addEventListener("scroll", this.bodyScroll, { passive: true });
  },
  mounted() {
    setTimeout(() => {
      this.setPos();
    }, 1000);
  },
  unmounted() {
    window.removeEventListener("resize", this.setPos);
    window.removeEventListener("scroll", this.bodyScroll);
  },
  methods: {
    setPos() {
      this.pos = [];
      let gap = 0;

      Object.keys(this.$refs).forEach((el) => {
        if (el.indexOf("bg") < 0 && el.indexOf("desc") < 0) {
          const Rect = this.$refs[el].getBoundingClientRect();

          if (el === "intro") {
            gap = Rect.y * -1;
          }

          this.pos.push({
            name: el,
            posy: Rect.y + gap - this.gap,
            height: Rect.height,
          });
        }
      });
      this.packageTop = this.pos[1].posy;
      this.packageHeight = this.pos[1].height;
      this.bodyScroll();
    },
    next() {
      window.scrollTo({
        top: this.pos[this.bgRight + 1].posy + this.gap,
        left: 0,
        behavior: "smooth",
      });
    },
    bodyScroll() {
      if (this.pos.length > 0) {
        // package 섹션 백그라운드
        const checkHeight = this.pos[1].posy + this.gap;

        if (window.scrollY <= checkHeight + 100) {
          this.packageRightBg =
            window.scrollY >= checkHeight
              ? 100
              : Math.round((window.scrollY * 100) / checkHeight);
        }

        if (window.scrollY < this.pos[1].posy) {
          this.bgRight = 0;
        } else if (
          window.scrollY > this.pos[1].posy &&
          window.scrollY < this.pos[2].posy
        ) {
          this.bgRight = 1;
        } else if (
          window.scrollY > this.pos[2].posy &&
          window.scrollY < this.pos[3].posy
        ) {
          this.bgRight = 2;
        } else if (window.scrollY > this.pos[3].posy) {
          this.bgRight = 3;
        }
      }
    },
  },
};
</script>
